import React from 'react';
import ability from '../../../../ability';
import WebApp from '../../../../webapp';
import { getCfgIndex, global_setConigValues, global_getConigValues } from '../util';
import { SingleSelect, SingleRadio, SingleSlider, SingleSwitch, SingleItem } from '../../SingleComponent';
import { message, Spin, TimePicker, Row, Col } from 'antd';
import moment from 'moment';

const format = 'HH:mm';

// 日夜模式组件
class DayNightMode extends React.Component {
    constructor (props) {
        super(props);
        var cfgindex = getCfgIndex(props);
        var {
            CameraConfig: {
                snapshotMode,
                subListData,
                subListData: {
                    VideoInDayNight: curVideoInDayNight
                }
            },
            channel: curChannel
        } = props;
        // var curSelect = curVideoInDayNight[curChannel][cfgindex].Mode;
        var curSelect = global_getConigValues('VideoInDayNight', 'Mode', curChannel, props, subListData, snapshotMode);
        this.state = {
            modeArr: [], // 模式下拉框的数据数组
            option: 0,
            sensi1: 50,
            onOff: false,
            timeRange: ['0:00', '23:59'],
            delayArr: [], // 延迟数据的数组
            sensitivityArr: [{ // 灵敏度的数据是写死的，不需要动态生成
                value: 3,
                tl: 'com.Low'
            }, {
                value: 2,
                tl: 'com.Middle'
            }, {
                value: 1,
                tl: 'com.High'
            }],
            curSelectMode: curSelect,
            capsex:{
                VideoInDayNight: {}
            },
            showSensitivityTip: false,
        };
        props.onRefs('attr_daynight', this);
    }

    isModeInStateModeArr = (curValue) => {
        var modeArr = this.state.modeArr; // 获取当前模式数组

        var isInArr = false;
        for (var i = 0, len = modeArr.length; i < len; i++) {
            if (modeArr[i].value === curValue) {
                isInArr = true;
                break;
            }
        }

        if (!isInArr && modeArr[0]) {
            return false;
        }
        return true;
    }

    componentWillReceiveProps (nextProps) {
        var {
            CameraConfig: {
                snapshotMode,
                subListData,
                subListData: {
                    VideoInDayNight: nextVideoInDayNight
                }
            },
            channel: nextChannel
        } = nextProps;
        if(nextChannel !== this.props.channel) {
            return;
        }
        var cfgindex = getCfgIndex(nextProps);
        var modeArr = this.state.modeArr; // 获取当前模式数组
        // var nextSelect = nextVideoInDayNight[nextChannel][cfgindex].Mode;
        var nextSelect = global_getConigValues('VideoInDayNight', 'Mode', nextChannel, nextProps, subListData, snapshotMode);
        /*
        * 判断当前的模式是不是已经在数组中存在
        * 避免在切换不同的模式数组时，有英文闪烁
        */
        if (this.isModeInStateModeArr(nextSelect)) {
            this.setState({
                curSelectMode: nextSelect
            });
        }
    }

    async componentDidMount () {
        var { channel = 0 } = this.props;
        let {sensitivityArr, showSensitivityTip} = this.state;
        await ability.get('VideoInputCapsEx', null, channel).then((capsex) => {
            var modeArr = this.getModelArr(capsex);
            var delayArr = this.getDelayArr(capsex);
            var TypeSupport = true; // 类型支持,默认为true

            // 类型支持
            if (capsex.VideoInDayNight.Type && capsex.VideoInDayNight.Type.length > 1) {
                // do nothing
            } else {
                TypeSupport = false;
            }
            //默认灵敏度1-3显示低中高，否则显示数值
            // 因之前球机项目的能力给错，SensitivityRange字段被废弃，多档时使用SensitivityRangeWithNum替代兼容
            if (capsex.VideoInDayNight.SensitivityRangeWithNum && (capsex.VideoInDayNight.SensitivityRangeWithNum[0] !== 1 || capsex.VideoInDayNight.SensitivityRangeWithNum[1] !== 3)) {
                sensitivityArr = this.getSensitivityArr(capsex.VideoInDayNight.SensitivityRangeWithNum);
                showSensitivityTip = true;
            }else if (capsex.VideoInDayNight.SensitivityRange && (capsex.VideoInDayNight.SensitivityRange[0] !== 1 || capsex.VideoInDayNight.SensitivityRange[1] !== 3)) {
                sensitivityArr = this.getSensitivityArr(capsex.VideoInDayNight.SensitivityRange);
                showSensitivityTip = true;
            } else {
                sensitivityArr = [{ // 没有灵敏度能力或者1-3显示低中高
                    value: 3,
                    tl: 'com.Low'
                }, {
                    value: 2,
                    tl: 'com.Middle'
                }, {
                    value: 1,
                    tl: 'com.High'
                }];
                showSensitivityTip = false;
            }

            this.setState({
                capsex,
                modeArr,
                TypeSupport,
                ModeSupport: modeArr.length !== 0, // 判断是否需要显示模式
                delayArr,
                DelaySupport: delayArr.length !== 0, // 判断是否需要显示模式
                sensitivityArr,
                showSensitivityTip,
            });
        });
    }

    active = async () => {
        await this.componentDidMount();
    }
    getSensitivityArr = (range) => {
        let arr = [];
        for (let i = range[0]; i <= range[1]; i++) {
            arr.push({
                value: i,
                tl: i
            });
        }
        return arr;
    }

    // 获取延迟下拉框的内容
    getDelayArr (capsex) {
        //根据延时能力初始化选择框，
        var arr = [];
        var { $t } = this.props;
        if (capsex.VideoInDayNight.DelayRange) {
            for (var i = capsex.VideoInDayNight.DelayRange[0]; i <= capsex.VideoInDayNight.DelayRange[1]; i++) {
                arr.push({
                    value: i,
                    tl: i + ' ' + $t('com.Second')
                });
            }
        }
        return arr;
    }

    // 获取模式下拉框的内容
    getModelArr (capsex) {
        var modes2trans = {
            'Color': 'com.Colorful',
            'Brightness': 'com.Auto',
            'BlackWhite': 'com.BlackWhite',
            'AlarmInput': 'com.TypeAlarmVideo',
            'Photoresistor': 'med.Photoresistor',
            'Gain': 'med.Gain',
            'IO': 'com.IO'
        };
        var modes = capsex.VideoInDayNight.Modes.slice();
        if (modes && modes.length) {
            modes = modes.map((item) => {
                return {
                    value: item,
                    tl: modes2trans[item]
                };
            });
            if (WebApp.GongAnDetect) {
                modes.push({
                    value: 'Mix',
                    tl: 'med.SoftLight'
                });
            }
        }
        return modes || [];
    }

    radioChange (value, name) {
        var cfgindex = getCfgIndex(this.props);
        var { setTemporaryConfig, channel } = this.props;
        var { subListData } = this.props.CameraConfig;
        var cfgName = 'VideoInDayNight';

        switch (name) {
        case 'Type':
            // subListData[cfgName][channel][cfgindex][name] = value;
            this.setConigValues(cfgName, name, value);
            break;
        default:
            break;
        }
        setTemporaryConfig(cfgName, subListData[cfgName], subListData);
    }

    selectChange (value, name) {
        // 数据处理
        var cfgindex = getCfgIndex(this.props);
        var { setTemporaryConfig, channel, $t } = this.props;
        var { subListData } = this.props.CameraConfig;
        var cfgName = 'VideoInDayNight';
        var oldValue = null;

        switch (name) {
        case 'Mode':
        case 'Sensitivity':
        case 'Delay':
            if (name === 'Mode') {
                this.setState({
                    curSelectMode: 'Mix'
                });
                if (WebApp.GongAnDetect && value === 'Mix') return;
            }
            // oldValue = subListData[cfgName][channel][cfgindex][name];
            // subListData[cfgName][channel][cfgindex][name] = value;
            oldValue = this.getConigValues(cfgName, name);
            this.setConigValues(cfgName, name, value);
            break;
        case 'option':
            this.setState({
                option: value
            });
            return;
        case 'sensi1':
            this.setState({
                sensi1: value
            });
            return;
        case 'onOff':
            this.setState({
                onOff: value
            });
            return;
        default:
            break;
        }

        setTemporaryConfig(cfgName, subListData[cfgName], subListData).catch(() => {
            var { capsex } = this.state;

            // 日夜模式切换失败
            if (name === 'Mode') {
                var isSupportVideoInDefog = capsex.VideoInDefog.SupportCamDefog;
                var isEnableDefog = this.getConigValues('VideoInDefog', 'Mode') === 'Manul' && this.getConigValues('VideoInDefog', 'CamDefogEnable');

                if (isSupportVideoInDefog && isEnableDefog) {
                    message.warning($t('ivs.DayNightDisabledTip'));

                    if (oldValue !== null) {
                        // subListData[cfgName][channel][cfgindex][name] = oldValue; // 复位数据
                        //setTemporaryConfig重新赋值了 subListData和this.props.CameraConfig.subListData不等，需要传subListData
                        this.setConigValues(cfgName, name, oldValue, subListData);
                    }

                    setTemporaryConfig(cfgName, subListData[cfgName], subListData);
                }
            }
        });
    }
    timeChange (str, idx) {
        let { timeRange } = this.state;
        timeRange[idx] = str;
        this.setState({
            timeRange: [...timeRange]
        });
    }
    getConigValues = (name, key) => {
        var { subListData, snapshotMode } = this.props.CameraConfig;
        var { channel } = this.props;
        return global_getConigValues(name, key, channel, this.props, subListData, snapshotMode);
    }

    setConigValues = (name, key, value, subListData = this.props.CameraConfig.subListData) => {
        var { snapshotMode } = this.props.CameraConfig;
        var { channel } = this.props;
        // subListData[name][channel][cfgindex][keys[0]][keys[1]][...]
        return global_setConigValues(name, key, value, channel, this.props, subListData, snapshotMode);
    }

    render () {
        // 当前模式在模式组中是第几个
        var cfgindex = getCfgIndex(this.props);
        var { channel, currentConfigMode, $t } = this.props; // 当前场景是自适应时,日夜模式需要disabled
        var { subListData } = this.props.CameraConfig;
        var { VideoInDayNight } = subListData;
        var { modeArr, delayArr, sensitivityArr, curSelectMode, option, sensi1, onOff, timeRange } = this.state; // 数据层
        var { TypeSupport, ModeSupport, DelaySupport } = this.state; // 能力层
        if(!subListData.VideoInDayNight[channel]) {
            return (
                <div className='empty'>
                    <Spin size="large" />
                </div>
            );
        }
        // 能力渲染
        var supportObject = (() => {
            var cfgindex = getCfgIndex(this.props);
            // 模式选择时,显示与模式相关的内容
            // var mode = subListData.VideoInDayNight[channel][cfgindex].Mode;
            var mode = this.getConigValues('VideoInDayNight','Mode');
            return {
                SensitivitySupport: curSelectMode === 'Brightness', // 灵敏度
                DelaySupport: curSelectMode === 'Brightness', // 延迟
            };
        })();

        var TypeRadio = (() => {
            // return VideoInDayNight[channel][cfgindex].Type;
            return this.getConigValues('VideoInDayNight','Type');
        })();

        // 模式
        var modeSelect = (() => {
            return curSelectMode;
        })();

        // 灵敏度
        var SensitivitySelect = (() => {
            // return VideoInDayNight[channel][cfgindex].Sensitivity;
            return this.getConigValues('VideoInDayNight','Sensitivity');
        })();

        // 延迟选择
        var DelaySelect = (() => {
            // return VideoInDayNight[channel][cfgindex].Delay;
            return this.getConigValues('VideoInDayNight','Delay');
        })();

        // 日夜模式模式下,日/夜模式需要disabled
        var disabledSelect = (() => {
            return currentConfigMode === 'DayNight';
        })();

        return (
            <>
                {
                    TypeSupport && (
                        <SingleRadio
                            {...this.props}
                            radioValue={TypeRadio}
                            radioName='Type'
                            tl='com.Type'
                            disabled={disabledSelect}
                            radioOptions={[
                                {
                                    value: 'Electron',
                                    tl: 'com.Electron'
                                }, {
                                    value: 'Mechanism',
                                    tl: 'med.ICR'
                                }
                            ]}
                            onChange={(value, name) => this.radioChange(value, name)} />
                    )
                }
                {
                    ModeSupport && (
                        <SingleSelect
                            {...this.props}
                            tl='com.Mode'
                            selectName='Mode'
                            value={modeSelect}
                            options={modeArr}
                            disabled={disabledSelect}
                            onChange={(value, name) => this.selectChange(value, name)} />
                    )
                }
                {
                    supportObject.SensitivitySupport && (
                        <SingleSelect
                            {...this.props}
                            tl='com.Sensitivity'
                            selectName='Sensitivity'
                            toolTipText={this.state.showSensitivityTip ? 'com.SmallNumColorChangeEarly' : ''}
                            options={sensitivityArr}
                            disabled={disabledSelect}
                            value={SensitivitySelect}
                            onChange={(value, name) => this.selectChange(value, name)} />
                    )
                }
                {
                    DelaySupport && supportObject.DelaySupport && (
                        <SingleSelect
                            {...this.props}
                            tl='com.Delay'
                            selectName='Delay'
                            disabled={disabledSelect}
                            value={DelaySelect}
                            options={delayArr}
                            onChange={(value, name) => this.selectChange(value, name)} />
                    )
                }
                {
                    WebApp.GongAnDetect && curSelectMode === 'Mix' && <>
                        <SingleSelect
                            $t={$t}
                            tl='com.Option'
                            selectName='option'
                            value={option}
                            options={[
                                {
                                    value: 0,
                                    tl: 'com.Auto'
                                }, {
                                    value: 1,
                                    tl: 'com.Manual'
                                }, {
                                    value: 2,
                                    tl: 'sys.Timing'
                                }, {
                                    value: 3,
                                    tl: 'com.StmAlarm'
                                }
                            ]}
                            onChange={(value, name) => this.selectChange(value, name)}
                        />
                        {option === 1 && <SingleSlider
                            $t={$t}
                            tl='com.Sensitivity'
                            sliderName='sensi1'
                            onChange={(value, name) => { this.selectChange(value, name); }}
                            sliderValue={sensi1}
                        />}
                        {option === 2 && <SingleItem $t={$t} tl='com.TimeSec'>
                            <Row style={{ maxWidth: '264px' }}>
                                <Col span={11}>
                                    <TimePicker
                                        value={moment(timeRange[0], format)}
                                        format={format}
                                        style={{width:'100%'}}
                                        onChange={(time, str) => this.timeChange(str, 0)}
                                    />
                                </Col>
                                <Col span={2} style={{ textAlign: 'center' }}>-</Col>
                                <Col span={11} style={{ textAlign: 'right' }}>
                                    <TimePicker
                                        value={moment(timeRange[1], format)}
                                        format={format}
                                        style={{ width: '100%' }}
                                        onChange={(time, str) => this.timeChange(str, 1)}
                                    />
                                </Col>
                            </Row>
                        </SingleItem>}
                        {option === 3 && <SingleSwitch
                            $t={$t}
                            switchName='onOff'
                            checked={onOff}
                            onChange={(value, name) => this.selectChange(value, name)}
                            tl='com.OnOff'
                        />}
                    </>
                }
            </>
        );
    }
}

export default DayNightMode;